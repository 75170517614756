@import '@/styles/colors.module';

.wrapper {
  margin-bottom: 16px;
}

.label {
  color: $light_gray;
}

.divider {
  border-right: 1px solid $input_border_gray;
  height: 16px;
  width: 1px;
}

.datePicker {
  padding: 0 !important;

  input {
    width: 84px !important;
    font-weight: 400;
  }
}

.button {
  &:not(:disabled) {
    border-bottom: 1px dashed $blue !important;
  }
}
