@import '@/styles/colors.module';

.countIndex {
  color: $dark_gray;
}

.table {
  .rowExpanded {
    :global {
      .ant-table-cell {
        background-color: $gray !important;
      }
    }
  }
}
