@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  background-color: $white;

  .title {
    margin-right: 16px;

    &Text {
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
      min-width: 225px;
      width: 225px;
    }
  }

  .input {
    width: 240px;
  }

  .buttonsWrapper {
    .buttonRepeated {
      display: flex;
      align-items: center;
    }
  }
}
