@import "@/styles/colors.module";

.modal {
  :global {

    .ant-modal {
      &-content {
        padding: 0 !important;
      }

      &-body {
        padding: 16px 32px 32px !important;
        background-color: $border_light_gray;
      }
    }
  }

  &Header {
    padding: 34px 64px;

    .title {
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }
  }

  .filterWrapper {
    margin-top: 20px;
  }
}