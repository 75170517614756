@import '@/styles/colors.module';

.mnn {
  width: 368px;

  &Code {
    color: $light_gray;
  }

  &Name {
    margin-bottom: 0 !important;
  }
}

.vicon {
  transition: 0.2s ease all;

  &.open {
    transform: rotate(180deg);
  }
}

.addCalcButton {
  display: flex !important;
  align-items: center;
}

.showInterchangeButton {
  padding: 0 !important;

  &Expanded {
    opacity: 0.5;
  }
}

.table {
  :global {
    .ant-table-cell {
      vertical-align: sub;
    }

    .ant-table-expanded-row-fixed {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .ant-table-expanded-row > .ant-table-cell {
      padding: 0 !important;
    }
  }

  .expandedRowContent {
    padding-left: 24px;
    padding-bottom: 18px;
    background-color: white;
    border-left: 8px solid $border_gray;
  }

  .expandedRow {
    :global {
      .ant-table-cell {
        border-bottom: none !important;
      }

      .ant-table-selection-column {
        border-left: 8px solid $border_gray;
      }
    }
  }
}
