@import '@/styles/colors.module';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  padding-top: 25px;

  &:not(:last-child) {
    padding-bottom: 25px;
    border-bottom: 1px solid $border_light_gray;
  }

  .columns {
    .column {
      .bold {
        font-weight: 500;
      }

      .gray {
        color: $light_gray;
      }

      .values {
        column-gap: 20px;
        row-gap: 8px;

        .checkboxWrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          .checkboxLabel {
            color: $light_gray;

            &.checked {
              color: $black;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .button {
    padding: 0 !important;
    border-bottom: 1px dashed $blue;
    border-radius: 0 !important;

    &Disabled {
      border-bottom: none;
    }
  }

  .copyButton {
    border-bottom: none !important;
    height: 17px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
