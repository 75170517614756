@import '@/styles/colors.module';

.row {
  padding: 5px 16px 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    border-bottom: 1px solid $border_gray;
  }

  &.drugForm {
    max-width: 400px;
  }

  &.dosage {
    max-width: 160px;
  }

  &.unit {
    max-width: 160px;
  }
}

.table {
  :global {
    .ant-table-cell {
      padding-top: 14px !important;
      padding-bottom: 14px !important;

      &:not(:nth-child(1)):not(:nth-child(2)) {
        padding-right: 0 !important;

        &:not(:nth-child(3)) {
          padding-left: 0 !important;
        }
      }
    }

    .ant-table-row {
      &:not(.ant-table-row-selected) {
        .ant-table-cell {
          background-color: $gray;
        }
      }
    }
  }
}
