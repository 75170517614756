@import '@/styles/colors.module';

.wrapper {
  display: flex;
  gap: 8px;

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    min-width: 22px;
    height: 22px;
    border-radius: 20px;
    background-color: $border_gray;
    font-size: 14px;
  }
}
