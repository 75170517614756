@import '@/styles/colors.module';

.wrapper {
  width: 336px;
}

.card {
  background: $white;
  border-radius: 8px;
  padding: 12px 16px;
}

.codeCard {
  background-color: white;
  padding: 12px 16px;
  border-radius: 8px;

  .chips {
    height: 17px;
    border-radius: 20px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    background-color: $gray;
  }

  .title {
    font-weight: 500;
    color: $light_gray;
  }

  .closeIcon {
    font-size: 16px;
    color: $light_blue;
    cursor: pointer;
    transition: 0.2s ease color;

    &:hover {
      color: $blue_2;
    }
  }

  .note {
    font-size: 12px;
    color: $light_gray;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .button {
    padding: 0 !important;
    display: flex;
    align-items: center;
    height: 22px;
  }
}
