@import '@/styles/colors.module';

.button {
  width: 130px;

  &:not([disabled]):not(:hover) {
    background-color: white;
    border-color: $blue;
    color: $blue;
  }

  .price {
    font-weight: 600;
    color: $black;
  }

  .buttonContent {
    display: inline-flex;
  }

  .vicon {
    transition: 0.2s ease all;

    &.open {
      transform: rotate(180deg);
    }
  }

  &.withPrice {
    background-color: $border_gray !important;
    border: none !important;
    color: $light_gray !important;
    width: auto;
  }
}
