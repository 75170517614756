@import '@/styles/colors.module';

.wrapper {
  :global {
    .ant-table-cell:first-of-type {
      padding-left: 64px;
    }
  }

  .title {
    display: flex;
    gap: 8px;
    color: $light_blue;

    .titleText {
      -webkit-line-clamp: 2;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .diagonal {
      margin-top: 3px;
      flex-shrink: 0;
      path {
        stroke: $light_blue;
      }
    }
  }

  .supplier {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .priceRow {
    text-align: right !important;
    font-weight: 500;
  }

  .tableFooter {
    padding: 0 48px;
    height: 100px;
    display: flex;
    align-items: center;
    gap: 64px;
  }

  .paginationContainer {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $light_gray;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .pageSize {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $light_blue;

    :global {
      .ant-select-selection-item {
        color: $light_blue;
      }
    }
  }

  .iconV {
    color: $light_blue;
  }

  .expired {
    color: $gold;
  }
}

.pagination {
  :global {
    .ant-pagination-item {
      a {
        color: $light_blue !important;
        font-weight: 600;
      }
    }
    .ant-pagination-prev {
      display: none !important;
    }
    .ant-pagination-next {
      display: none !important;
    }
    .ant-pagination-item-active {
      background-color: $gray !important;

      border: none !important;
      border-radius: 20px !important;
      a {
        font-weight: 700;
        color: $black !important;
      }
    }
  }
}

.tooltipText {
  text-align: center;
}
