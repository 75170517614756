@import '@/styles/colors.module';

.badge {
  color: $light_blue !important;
  background-color: $border_gray !important;
  border-radius: 20px;
  padding: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  height: 22px;
  min-width: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  color: $light_gray;
}
