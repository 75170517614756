@import '@/styles/colors.module';

.table {
  margin-top: 16px;

  .disabledRow {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
    transition: .2s ease-in-out opacity;
  }
}

.filter {
  padding: 0 32px;
}

.bold {
  font-weight: 500;
}

.orangeText {
  color: $gold;
}
