@import '@/styles/colors.module';
@import "@/styles/mixins";

.wrapper {
  padding: 28px 32px;
  background-color: $white;
  @include sticky-element();

  .addButton {
    display: flex !important;
    align-items: center;
  }

  .exportButton {
    display: flex;
    align-items: center;
  }

  .priceLabel {
    color: $light_gray;
  }
}
