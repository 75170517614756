@import '@/styles/colors.module';

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;

      .ant-modal-header {
        padding: 32px 64px 32px 64px;

        .ant-modal-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 38px;
        }
      }

      .ant-modal-body {
        background-color: $gray;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow-y: auto;
        max-height: calc(100vh - 250px);
      }

      .ant-modal-footer {
        margin-top: 0 !important;
        text-align: start;
        padding: 16px 64px;
      }
    }
  }
}
