@import '@/styles/colors.module';

.modal {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $dark_gray;
    border-radius: 16px;
  }

  :global {
    .ant-modal-content {
      padding: 0 0 !important;

      .ant-modal-header {
        border-radius: 16px !important;
        padding: 45px 64px;
        margin: 0;

        .ant-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
      }
      .ant-modal-footer {
        border-top: 1px solid $border_gray;
        margin: 0;
        padding: 28px 64px;
        background-color: $gray;
        border-radius: 0 0 16px 16px !important;
        text-align: start;
      }

      .ant-modal-body {
        background-color: $gray;
        padding: 32px 64px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .ant-checkbox + span {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
      .ant-modal-close {
        top: 48px;
        right: 64px;
      }
      .ant-modal-close-x {
        font-size: 24px;
      }
    }
  }

  .characteristicList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
  }
  .characteristicItem {
    display: grid;
    grid-template-columns: 380px 610px;
    gap: 40px;
  }
  .characteristicName {
    color: $light_gray;
  }
}
