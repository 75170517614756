@import '@/styles/colors.module';

.content {
  background-color: $gray;
  padding: 24px 32px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;
    }

    .ant-modal-header {
      margin-bottom: 0 !important;
    }
  }

  .header {
    padding: 25px 32px;

    &Title {
      font-weight: 600;
      font-size: 32px;
      line-height: 28px;
    }
  }

  &Body {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
