@import '@/styles/colors.module';

.wrapper {
  background-color: $gray;
  min-height: calc(100vh - 96px);
}

.wrapperError {
  background-color: $white;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: $white;
  padding: 32px 64px;
}

.loaderWrapper {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
}
