@import '@/styles/colors.module';

.characteristicItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  .characteristic {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
