@import '@/styles/colors.module';

.navigation {
  display: flex;
  flex-direction: column;

  .link {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;

    &.notActive {
      &:not(:hover) {
        color: black;
      }
    }
  }

  &.navigationGPB {
    height: 45px;
    justify-content: space-between;

    .parentList {
      .link {
        font-weight: 500;
        font-size: 15px;
        height: 11px;
      }
    }

    .childrenList {
      padding-top: 21px;

      .link {
        font-size: 12px;
        font-weight: 500;
        height: 8px;
      }
    }
  }

  &ROS {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $border_gray;
      left: 0;
      bottom: 46px;
    }

    .link {
      letter-spacing: -0.5%;
      font-weight: 500;

      &.active {
        font-weight: 600;
      }
    }

    .parentList {
      .link {
        position: relative;
        font-size: 14px;
        height: 10px;

        &.active {
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background-color: $blue;
            left: 6px;
            bottom: -29px;
          }
        }
      }
    }

    .childrenList {
      padding-top: 45px;

      .link {
        font-size: 16px;
        height: 11px;
      }
    }
  }

  &PIRSON {
    .parentList {
      .link {
        font-weight: 600;
        font-size: 14px;
        height: 10px;
      }
    }

    .childrenList {
      padding-top: 25px;

      .link {
        font-size: 12px;
        font-weight: 500;
        height: 9px;
      }
    }
  }
}
