@import '@/styles/colors.module';

.paginationContainer {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $light_gray;
  display: flex;
  align-items: center;
  gap: 8px;

  .loadMore {
    color: $light_blue;
    cursor: pointer;
    border: none;
  }
}

.pagination {
  :global {
    .ant-pagination-item {
      a {
        color: $light_blue !important;
        font-weight: 600;
      }
    }
    .ant-pagination-prev {
      display: none !important;
    }
    .ant-pagination-next {
      display: none !important;
    }
    .ant-pagination-item-active {
      background-color: $gray !important;

      border: none !important;
      border-radius: 20px !important;
      a {
        font-weight: 700;
        color: $black !important;
      }
    }
  }
}
