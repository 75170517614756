@import '@/styles/colors.module';

.select {
  width: 470px;
  height: 40px !important;
}

.popoverContent {
  color: $light_gray;
}
