@import '@/styles/colors.module';

.header {
  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
  }

  &Link {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-bottom: 1px dashed $blue;
    height: fit-content;
    white-space: nowrap;
    margin-top: 10px;
  }
}

.content {
  background-color: $gray;
  padding: 24px 64px;

  .text {
    color: $light_gray;
  }

  .mdText {
    white-space: pre-wrap;
  }

  .note {
    padding-left: 16px;
    border-left: 2px solid $gold;
    font-weight: 500;
  }

  .caption {
    font-size: 16px;
    font-weight: 500;
  }
}

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;
    }
  }

  .header {
    padding: 32px 64px 32px 64px;
  }

  &Body {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
