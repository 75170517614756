@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  background-color: white;

  .header {
    padding: 16px 0 48px;
    overflow: hidden;
  }

  .inputIcon {
    cursor: pointer;
    color: $light_gray;
    margin-right: 2px;
  }

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .input {
    width: 540px;
  }

  .searchBtn {
    display: flex;
    align-items: center;
    color: $white;
    background: $light_blue;
    padding: 8px 11.5px;
  }

  .dateText {
    font-size: 14px;
    font-weight: 500;
    color: $light_gray;
  }

  :global {
    .ant-collapse-content {
      display: none;
    }
  }

  .treeSelect {
    width: 320px;
    height: 40px;
  }

  .treePlaceholder {
    color: $black;
  }

  .expand {
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    opacity: 0.4;
    cursor: pointer;
  }

  .expandIconActive,
  .expandIcon {
    transition: transform 0.25s linear;
  }

  .expandIconActive {
    transform: rotate(-180deg);
  }

  .expandIcon {
    transform: rotate(0deg);
  }
}

.spin {
  height: 246px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
