@import '@/styles/colors.module';

.table {
  :global {
    .ant-table {
      border-radius: 8px;

      &-row {
        height: 90px !important;
        position: relative !important;

        .ant-table-cell {
          vertical-align: baseline;
        }
      }
    }
  }
}
