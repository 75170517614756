@import '@/styles/colors.module';

.select {
  width: 416px !important;
  height: 40px !important;
}

.form {
  margin-left: 32px !important;
  margin-bottom: 28px;

  .row {
    display: flex;
    align-items: center;
    gap: 20px;

    label {
      color: $light_gray !important;
      min-width: 130px;
    }
  }
}
