@import '@/styles/colors.module';

.wrapper {
  padding: 20px 64px 32px 64px;

  .tabs {
    :global {
      .ant-tabs-nav {
        border-bottom: 1px solid $border_gray;
      }
      .ant-tabs-tab-btn {
        display: flex !important;
        flex-direction: row-reverse;
        gap: 8px;
        font-weight: 500;

        .ant-tabs-tab-icon {
          margin-inline-end: 0 !important;
          height: 22px !important;
        }
      }
    }
  }

  .badge {
    color: $black !important;
  }
}
