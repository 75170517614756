@import '@/styles/colors.module';

.modal {
  :global {
    .ant-modal-content {
      padding: 0 0 !important;

      .ant-modal-header {
        border-radius: 16px !important;
        padding: 45px 64px;
        margin: 0;

        .ant-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
      }

      .ant-modal-footer {
        border-top: 1px solid $border_gray;
        margin: 0;
        padding: 28px 64px;
        background-color: $gray;
        border-radius: 0 0 16px 16px !important;
        text-align: start;
      }

      .ant-modal-body {
        background-color: $gray;
        padding: 32px 64px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .ant-checkbox + span {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }

      .ant-modal-close {
        top: 48px;
        right: 64px;
      }

      .ant-modal-close-x {
        font-size: 24px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .top {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .topDrug {
      border-bottom: 1px solid $border_gray;
      padding-bottom: 12px;
    }

    .text,
    .textShort {
      width: 250px;
      flex-shrink: 0;
      font-weight: 400;
      color: $light_gray;
      font-size: 14px;
    }

    .textShort {
      width: 126px;
    }

    .select {
      height: 40px;
      width: 560px;
    }

    .itemNameInput {
      width: 338px;
    }

    .itemNameInputShort {
      width: 260px;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .isDrug {
      width: 205px;
      gap: 8px;
    }

    .isDrugText {
      color: $light_gray;
      font-size: 12px;
    }

    .isDrugTextChecked {
      color: $black;
      font-weight: 500;
    }

    .input {
      width: 260px;
    }

    .datePicker {
      width: 130px;
      height: 40px;
    }

    .priceForCalculation {
      display: flex;
      padding-top: 12px;
      border-top: 1px solid $border_gray;
      gap: 32px;

      .title {
        width: 250px;
        display: flex;
        align-items: center;
        gap: 5px;

        .questionIcon {
          cursor: help;
          display: flex;
          align-items: center;
          color: $light_gray;
        }
      }

      .priceForCalculationPrice {
        height: 40px;
        background: $border_gray;
        width: 260px;
        padding: 4px 12px;
        border-radius: 4px;
        border: 1px solid $border_gray;
        display: flex;
        align-items: center;
      }
    }
  }

  .cancelBtn {
    width: 122px;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    background: transparent;
    border: 1px solid $border_gray;
    color: $light_gray;
  }

  .submitBtn {
    width: 122px;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    background: $light_blue;
    color: $white;
  }
}
