@import '@/styles/colors.module';
.wrapper {
  .tableFooter {
    padding: 0 35px;
    height: 100px;
    display: flex;
    align-items: center;
    gap: 64px;
  }

  .paginationContainer {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $light_gray;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .pageSize {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $light_blue;

    :global {
      .ant-select-selection-item {
        color: $light_blue;
      }
    }
  }

  .iconV {
    color: $light_blue;
  }

  .ikz {
    display: flex;
    gap: 8px;
    align-items: center;
    color: $blue;

    .ikzText {
      font-weight: 500;
      border-bottom: 1px dashed $blue;
    }
  }

  .priceRow {
    text-align: right !important;
  }

  .table {
    :global {
      .ant-table-row-expand-icon-cell {
        padding: 16px !important;
      }

      .ant-table-row-expand-icon {
        display: none !important;
      }

      .ant-table-expanded-row {
        .ant-table-cell {
          background-color: white !important;
        }
      }
    }

    .linkedPurchase {
      width: 1186px;
      margin-left: 48px;
      display: grid;
      grid-template: auto / repeat(2, fit-content(300px)) 1fr;
      gap: 50px;
    }

    .purchaseRow {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .purchaseSum {
      text-align: right;
    }

    .purchaseLink {
      color: $light_blue;
      display: flex;
      align-items: flex-start;
      gap: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      svg {
        transition: opacity 0.1s ease-in;
        opacity: 0;
      }

      &:hover {
        color: $blue;

        svg {
          transition: opacity 0.1s ease-in;
          color: $blue;
          opacity: 1;
        }
      }
    }

    .remains {
      font-weight: 500;
    }

    .linked {
      cursor: pointer;
      color: $blue;
    }

    .expandedRow {
      background: #eff2f5;
      padding-right: 0;

      .linked {
        opacity: 0.5;

        .viconActive {
          transform: rotate(-180deg);
        }
      }

      :global {
        .ant-table-cell {
          background: #eff2f5 !important;
        }
      }
    }
  }
}

.pagination {
  :global {
    .ant-pagination-item {
      a {
        color: $light_blue !important;
        font-weight: 600;
      }
    }
    .ant-pagination-prev {
      display: none !important;
    }
    .ant-pagination-next {
      display: none !important;
    }
    .ant-pagination-item-active {
      background-color: $gray !important;

      border: none !important;
      border-radius: 20px !important;
      a {
        font-weight: 700;
        color: $black !important;
      }
    }
  }
}
