@import '@/styles/colors.module';

.documentationText {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.exportIcon {
  min-width: fit-content;
}

.documentationTextHidden {
  display: none !important;
}

.showMore {
  cursor: pointer;
  font-weight: 500;
  display: flex;
  color: $light_blue;
  transition: 0.2s ease color;

  &:hover {
    color: $blue;
  }
}
