@import '@/styles/colors.module';

.text {
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.link {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $light_blue !important;
}
