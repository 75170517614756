@import '@/styles/colors.module';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  background-color: $white;
  padding: 29px 32px 25px 32px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid $border_light_gray;

  .columns {
    .column {
      color: $light_gray;
      font-weight: 500;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 32px;

    .button {
      display: flex !important;
      align-items: center;
      padding: 0 !important;
    }
  }
}
