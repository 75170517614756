.input {
  width: 86px !important;
  height: 40px !important;

  :global {
    .ant-input-number-input {
      height: 40px !important;
    }
  }
}
