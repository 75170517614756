@import '@/styles/colors.module';

.card {
  padding: 8px 12px;
  background-color: white;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
  transition: 0.2s ease all;

  &:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
  }

  &Name {
    max-width: 245px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $light_blue;
  }

  &Price {
    font-weight: 500;
    font-size: 16px;

    &.go {
      color: $gold;
    }
  }

  .crossIcon {
    color: $blue;
    cursor: pointer;
  }
}
