@import '@/styles/colors.module';

.input {
  width: 140px !important;

  &Supplier {
    width: 620px !important;
  }
}

.button {
  width: 122px !important;

  &Add {
    &:not(:hover) {
      background-color: $light_blue !important;
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;

      .ant-modal-header {
        padding: 32px 64px;

        .ant-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
      }

      .ant-modal-body {
        background-color: $gray;
        padding: 32px 64px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.form {
  :global {
    .ant-form-item-label {
      label {
        color: $light_gray !important;
        height: 40px !important;
      }
    }
  }
}
