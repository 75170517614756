@import '@/styles/colors.module';

.list {
  padding: 0 32px 30px 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: $white;
  position: relative;

  .listTitle {
    font-weight: 500;
    font-size: 16px;
    padding-top: 30px;

    &:not(:first-child) {
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: $border_light_gray;
        left: 0;
        transform: translateY(-32px);
      }
    }
  }

  .borderlessRow {
    border-bottom: none !important;
  }
}
