@import '@/styles/colors.module';

.characteristicName {
  color: $light_gray;
}
.characteristicValue {
  font-weight: 500;
}

.overflowed {
  color: $blue;
}
