@import '@/styles/colors.module';

.infoLabel {
  min-width: 230px;
  color: $light_gray;
}

.infoValue {
  font-size: 16px;
}

.infoList {
  max-width: 750px;
}

.input {
  width: 140px !important;
}

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;

      .ant-modal-header {
        padding: 32px 64px;

        .ant-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
      }

      .ant-modal-body {
        background-color: $gray;
        padding: 32px 64px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
