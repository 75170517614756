@import '@/styles/colors.module';

.selectLabel {
  color: $light_blue;

  &OnlyText {
    color: $light_gray;
  }
}

.select {
  :global {
    .ant-select-selector {
      padding-left: 0 !important;
    }
  }
}
