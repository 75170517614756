@import '@/styles/colors.module';

.tabs {
  padding: 0 16px !important;
  padding-right: 0 !important;
  width: calc(100vw - 52px);

  :global {
    .ant-tabs-nav {
      margin-left: 32px !important;
      border-bottom: 1px solid $border_gray;
    }

    .ant-tabs-tab-btn {
      font-weight: 500;
    }
  }
}
