@import '@/styles/colors.module';

.characteristicTooltip {
  -webkit-line-clamp: 10;
  line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.characteristics {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 36px;
}

.characteristicsOverflowed {
  p {
    color: $blue !important;
  }
  cursor: pointer;
}

.characteristic {
  color: $light_gray;
  span {
    font-weight: 400;
  }
}
