@import '@/styles/colors.module';

.formItem {
  margin-bottom: 0 !important;

  .input {
    width: 260px !important;
  }

  :global {
    .ant-form-item-control {
      position: relative;

      .ant-form-item-explain-error {
        width: 260px;
        position: absolute;
        bottom: -23px;
      }
    }
  }
}
