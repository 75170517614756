@import '@/styles/colors.module';

.table {
  .rowEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
  }

  .name {
    font-size: 16px;
  }

  .gray {
    color: $light_gray;
  }

  .copyIcon {
    color: $light_blue !important;
    opacity: 0;
    transition: 0.15s opacity ease;
  }

  .copyable {
    color: $light_blue;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    transition: 0.15s color ease;

    &:hover {
      color: $blue;

      .copyIcon {
        opacity: 1;
      }
    }
  }

  .linkRegNum {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;

    span {
      border-bottom: 1px dashed $blue;
      white-space: nowrap;
    }
  }

  :global {
    .ant-table-tbody {
      .ant-table-cell {
        font-weight: 400;
      }

      .ant-table-selection-column {
        vertical-align: top !important;
      }
    }

    .ant-table-footer {
      padding: 28px 32px !important;
    }
  }
}
