@import '@/styles/colors.module';

.select {
  width: 288px;
  height: 40px !important;
}

.crossButton {
  width: 22px !important;
  height: 22px !important;
}
