@import '@/styles/colors.module';

.header {
  padding: 0 32px;
  margin-top: 16px;
}

.radioGroupLabel {
  color: $light_gray;
}

.table {
  margin-top: 16px;

  .bold {
    font-weight: 500;
  }

  .refreshButton {
    height: 18px !important;
  }

  .disabledRow {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
    transition: .2s ease-in-out opacity;
  }
}
