@import '@/styles/colors.module';

$labelWith: 245px;

.label {
  min-width: $labelWith;
  color: $light_gray;
}

.list {
  max-width: calc(920px + $labelWith);
  font-size: 16px;
  margin-top: 16px;

  .standardContractLink {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
}
