@import '@/styles/colors.module';

.wrapper {
  padding: 8px 32px;
  background-color: $border_gray;
  border-radius: 2px;
  margin-bottom: 16px;
  position: relative;

  .cardList {
    row-gap: 8px;
    column-gap: 16px;
  }

  .priceInfo {
    height: 100%;
    padding-left: 16px;
    flex-shrink: 0;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      background-color: $border_gray;
      height: calc(100% - 16px);
      top: 8px;
      margin-left: -16px;
    }

    &Row {
      color: $light_gray;

      span {
        font-weight: 500;
      }
    }
  }

  .button {
    display: flex !important;
    align-items: center;
    height: 36px !important;
    background-color: transparent !important;
  }
}

.green {
  color: $green;
}

.red {
  color: $red;
}
