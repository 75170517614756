@import '@/styles/colors.module';

.form {
  :global {
    .ant-form-item-label {
      label {
        color: $light_gray;
        height: 40px !important;
      }
    }
  }

  .datePicker {
    width: 160px;
    height: 40px;
  }

  .select {
    width: 336px !important;
    height: 40px !important;
  }

  .button {
    padding: 0 !important;
    border-bottom: 1px dashed $blue;
    border-radius: 0 !important;

    &[disabled] {
      border-bottom: none;
    }
  }
}
