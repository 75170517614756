@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  height: 100%;

  .table {
    :global {
      .ant-table-thead .ant-table-cell {
        white-space: nowrap;
      }
    }
  }

  .region {
    min-width: 200px;
  }

  .documentation {
    min-width: 266px;
  }

  .suppliers {
    min-width: 272px;
  }

  .tableFooter {
    padding: 0 35px;
    display: flex;
    align-items: center;
    gap: 64px;
  }

  .pageSize {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $light_blue;

    :global {
      .ant-select-selection-item {
        color: $light_blue;
      }
    }
  }

  .iconV {
    color: $light_blue;
  }

  .row {
    vertical-align: top;
  }
}
